<template>
  <el-dialog title="推广" :visible.sync="show">
    <div style="padding-bottom:32px;">
      <div style="padding-bottom:16px;">分享链接</div>
      <ClipInput :value="content" />
    </div>
    <div>
      <QrCode class="qrCode-wrap" :content="content" size="220"></QrCode>
      <div style="padding-top:16px">
        可以通过小程序【极飞服务】首页右上角的扫描功能打开
      </div>
    </div>
  </el-dialog>
</template>
<script>
import ClipInput from '@/components/tool/ClipInput'
import QrCode from '@/components/AppQrcode.vue'
export default {
  components: {
    QrCode,
    ClipInput,
  },
  data() {
    return {
      show: false,
    }
  },
  props: {
    visible: Boolean,
    content: String,
  },
  watch: {
    show(val) {
      if (val === false) {
        this.$emit('update:visible', false)
      }
    },
    visible(val) {
      val && (this.show = true)
    },
  },
}
</script>
<style lang="scss" scoped>
.qrCode-wrap {
  margin-left: 0;
}
</style>
